




import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import UserStore from '@/store/modules/user';

@Component({
  name: 'Logout',
})
export default class Logout extends Vue {
  user = getModule(UserStore, this.$store);

  created() {
    this.user.logout();
    this.$router.push({ name: 'login' });
  }
}
